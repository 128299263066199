@import "@styles/theme", "@styles/mixins", "@styles/animations";

.versions {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: $background-primary;

  .btnPrev,
  .btnNext {
    display: none;

    @include desktop {
      display: flex;
    }

    width: 45px;
    height: 45px;
    background-color: $background-secondary;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    color: $default-black;

    path {
      color: $default-black;
    }
  }

  .container {
    position: relative;
    z-index: 1;
    margin-bottom: 3.5rem;

    @media (min-width: 961px) {
      margin-bottom: 0;
    }
  }

  .containerSwipper {
    .carousel {
      width: 100%;
      display: flex;
      justify-content: center;
      position: relative;

      .images {
        width: 100%;
      }

      @media (min-width: 961px) {
      }
    }
  }

  .infoBg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $background-primary;
    z-index: 0;
    transform: translateY(-130px);
    padding-top: 8rem !important;

    @include desktop {
      position: absolute;
      transform: translateY(-175px);
      padding-top: 10rem !important;
    }
  }

  .containerCarInfo {
    margin: 25px 0px 1.5rem;
    z-index: 1;
    position: relative;

    padding: 0 15px;
    width: 100%;

    @include desktop {
      margin: 25px 0px 0;
    }
  }

  .mobileTitle {
    color: $text-menu-link;
    display: inline-block;
    text-align: center;
    font-style: italic;
    font-family: "Futura", Arial, sans-serif;
    margin-right: 2rem;
    font-size: 2rem;
    width: 100%;
    padding: 0 15px;

    .discovery {
      font-weight: 400 !important;
      position: relative;
      display: inline;
    }

    strong {
      font-weight: 800;
      color: $text-menu-link;
    }
  }

  .title {
    z-index: 999;

    p {
      color: $text-secondary;
      font-size: 1.125rem;
      text-align: center;
    }

    @include desktop {
      padding-top: 0;
    }
  }

  .names {
    display: flex;
    justify-content: space-around;
    margin: 29px 0 10px;
    list-style: none;
    position: relative;
    z-index: 1;
    width: 100%;

    @include desktop {
      width: 30%;
    }

    .btns {
      font-size: 1.25rem;
      font-family: "Futura", Roboto, Helvetica Neue, sans-serif;
      text-transform: uppercase;
      color: #a8a89c;
      font-weight: 400;
      position: relative;
      height: 40px;
      width: 41.5%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 3px solid $terciary;
      padding-top: 4px;
      background-color: #fdfdf5;
      cursor: pointer;

      &.active {
        color: $default-white;
        background-color: $terciary;

        &::after {
          position: absolute;
          display: block;
          content: "";
          z-index: 1;
          bottom: -10px;
          left: calc(50% - 10px);
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid $terciary;
        }
      }
    }

    @media (min-width: 961px) {
      width: 30%;
      justify-content: space-evenly;
      margin: 2rem auto 0rem;
      padding: 0 5px 4px;

      .btns {
        font-size: 1.1rem;
        height: 40px;

        &.active {
          color: #fff;
          background-color: $terciary;

          &::after {
            left: calc(50% - 10px);
            bottom: -18px;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid $terciary;
          }
        }
      }
    }
  }

  .colorsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;

    @include desktop {
      transform: translateY(130px);
      margin-bottom: 1.5rem;
    }

    p {
      font-size: 1rem;
      font-weight: 600;
      color: $text-menu-link;
      width: auto;
      letter-spacing: 0.5px;
    }
  }

  .colors {
    margin-top: 22px;
    margin-bottom: 0px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    margin-bottom: 15px;
    padding: 0px 0 0px;
    align-items: center;
    border-radius: 50px;

    nav {
      display: flex;
      margin: 0 0.6rem;
      gap: 20px;

      button {
        position: relative;
        width: 20px;
        height: 20px;
        border: none;

        &.active {
          .currTextColor {
            display: block;
            position: absolute;
            transform: translateX(-37%);
            display: flex;
            justify-content: center;
            text-transform: uppercase;

            .teste {
              top: -15px;
              position: relative;
              border-radius: 50% 50% 50% 0;
              background-color: $text-secondary;
              width: 30px;
              height: 30px;
              transform: rotate(-45deg);
              font-size: 0.8rem;
            }

            .teste2 {
              text-transform: uppercase;
              text-align: center;
              font-weight: 800;
              position: absolute;
              color: #222652;
              font-size: 0.8rem;
              font-family: "Futura", Arial, sans-serif;
              bottom: -20px;
            }
          }

          .currCircleColor {
            border-color: $default-black;
          }
        }

        .currCircleColor {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          border: 0.1px solid $default-black;
        }

        .currTextColor {
          white-space: nowrap;
          display: none;
        }
      }
    }
  }

  .info {
    * {
      font-family: $font;
    }

    .optional {
      width: 100%;
      display: flex;
      flex-direction: column;
      min-height: 230px;
      margin-top: 4rem;

      @include desktop {
        margin-top: 0;
      }

      .fullname {
        width: 100%;
        font-weight: 500;
        font-size: 1.5rem;
        text-transform: uppercase;
        position: relative;
        color: $text-primary;
        display: flex;
        line-height: 22px;
        gap: 8px;
        flex-direction: row;
        margin-bottom: 1rem;

        strong {
          margin-bottom: 0.6rem;
          width: 100%;
          font-weight: 900;
          font-size: 3rem;
          text-transform: uppercase;
          position: relative;
          color: $text-primary;
          display: flex;
          gap: 8px;
          letter-spacing: -2px;
          flex-direction: row;
          margin-top: 10px;
        }
      }

      p {
        font-size: clamp(1rem, 1vw, 1rem);
        color: $default-white;
        text-align: center;
      }

      .description {
        text-align: left;
        color: $default-black;
        font-weight: 400;
        font-size: 1rem;
        width: 70%;
      }

      .line {
        content: "";
        width: 100%;
        background: $terciary;
        height: 1px;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }

      ul {
        list-style: none;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        margin: 0;
        margin-bottom: 4rem;
        gap: 8px;
        padding: 0;

        @include desktop {
          margin-bottom: unset;

          li {
            margin-right: 16px;
            margin-left: 0px;
          }
        }

        li {
          display: flex;
          flex-direction: column;
          margin-right: 10px;
          margin-top: 21px;
            max-width: 150px;
            width: 100%;
          
          @media (min-width: 1400px){
            max-width: 130px;

          }

          .textHolder {
            padding-top: 10px;

            .lineDescription {
              content: "";
              width: 100%;
              background: $terciary;
              height: 2px;
              margin-top: 1rem;
              margin-bottom: 0.5rem;
            }

            p {
              font-weight: 700;
              font-size: 10px;
              color: $text-primary;
              text-transform: uppercase;
              text-align: left;
              line-height: 12px;
              font-size: 10px;

              @include desktop {
                font-size: 13px;
              }
            }
          }
        }
      }
    }

    .price {
      padding: 0;
      display: inline;

      width: 35% !important;

      @media (min-width: 1400px){
        width: 25% !important;

      }

      @include desktop {
        padding: 1.5rem 1.5rem 0.5rem 1.5rem;
        transform: translateY(64px);
      }

      p {
        font-size: 1rem;
        text-transform: uppercase;
        color: $text-menu-link;

        strong {
          font-size: 1.5rem;
          color: $text-menu-link;
          font-weight: 800;
        }
      }

      .ctas {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        flex-direction: column;
        margin-top: 1rem;
        align-items: flex-end;

        .ctaLinks {
          width: 100%;
          display: flex;
          justify-content: center;
          flex-direction: column;
          gap: 8px;

          a {
            width: 100%;
            margin-left: 28px;
            text-decoration: none;
            button {
              width: 100% !important;
            }
          }
        }

        .download {
          width: 100%;
          display: flex;
          justify-content: flex-end;
        }

        .interest {
          margin-left: 1rem;

          > div {
            svg {
              color: #fc1430 !important;
              width: 13px;
              height: 13px;
            }
          }
        }

        .ctaButton {
          margin-bottom: 0.5rem;

          > div {
            background-color: $primary;
            border-radius: 8px;
            padding: 0.98rem;

            @include desktop {
              width: 250px;
              height: 40px;
            }

            svg {
              width: 13px;
              height: 13px;
            }

            p {
              font-family: "Futura", Arial, sans-serif;
              text-transform: none;
              font-size: 0.875rem;
              font-weight: 800;
              font-style: oblique;
              color: $default-white;
              display: flex;
              justify-content: space-between;
            }
          }
        }
      }

      .comparativeButton {
        margin-top: 8px;
      }
    }

    .payment {
      display: flex;

      justify-content: space-between;
      align-items: flex-end;
      .pricevalue{
        color: #ff0000;
        padding-bottom: 5px;
      }
    }

    @include desktop {
      display: flex;
      justify-content: space-between;
      padding: 0 clamp(70px, 4vw, 90px);
      align-items: flex-start;

      .optional {
        display: flex;
        justify-content: flex-end;

        min-height: 200px;
          width: 35%;

        @media (min-width:1400px){
          width: 25%;
        }

        ul {
          margin: 0px 0 0;
        }
      }

      .price {
        width: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        padding: 0;

        .payment {
          @include desktop {
            justify-content: space-between;
          }

          a {
            font-weight: 600;
          }
        }

        .ctaLinks {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
      }
    }
  }

  @media (min-width: 961px) {
    padding-top: 2rem;

    .info {
      margin-top: 0;
    }

    background-color: $background-primary;

    .btnPrev,
    .btnNext {
      display: none;

      @include desktop {
        display: flex;
      }

      position: absolute;
      top: 220px;
      z-index: 2;

      &:hover {
        background-color: #e2d9d9;
      }
    }

    .btnPrev {
      transform: rotate(180deg);
      left: $section-horizontal-padding-desktop;

      &:hover {
        background-color: #ddaf66;
      }
    }

    .btnNext {
      right: $section-horizontal-padding-desktop;

      &:hover {
        background-color: #ddaf66;
      }

      div {
        img {
          transform: rotate(180deg);
          margin: 3px 0 0 2px;
          width: 10px;
          height: 10px;
        }
      }
    }
  }
}

.imageHolder {
  width: 104px;
  height: 48px;

  img {
    object-fit: cover;
  }

  position: relative;
  border-bottom: 4px solid #707070;
}

.pulsIcon {
  position: absolute;
  top: -15%;
  left: 0px;
  width: 10%;
  z-index: -1;
  display: none;

  @include desktop {
    display: unset;
  }
}

.navigation {
  width: 100%;
  width: 100%;
  padding-top: 40px;

  .navslide {
    transform: skew(-20deg) !important;
    border: 1px solid $text-primary;

    display: flex;
    width: 150px !important;

    @include desktop {
      width: 200px !important;
    }
    @media (min-width: 1679px) {
      width: 245px !important;
    }

    align-items: center;
    justify-content: baseline;
    justify-self: auto;

    background-color: $primary;

    .navname {
      padding: 10px 8px 10px 8px;
      height: fit-content;
      transform: skew(20deg);
      white-space: nowrap;
      width: 100%;
      text-align: center;
      color: $default-black;
      font-family: $font;
      text-transform: uppercase;
      cursor: pointer;
      font-size: 16px;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%; /* Cobrir 100% da largura do pai */
        height: 100%; /* Cobrir 100% da altura do pai */
        background-color: rgba(0, 0, 0, 0); /* Transparente (preto com 50% de opacidade) */
        pointer-events: none;}

      }
      @include desktop {
        font-size: 21px;
      }

    &:hover {
      background-color: $terciary;
      color: $default-white;
      .navname {
        color: $default-white;
      }
    }

  }

  .navslide_active {
    background-color: $terciary;
    color: $default-white;
    border: none;

    .navname {
      color: $default-white;
    }
  }

  @include desktop {
    .navslide {
      div {
        justify-content: center !important;
        background-color: red !important;

        &:nth-child(1) {
          justify-content: center !important;
          background-color: red !important;
        }
      }
    }
  }
}

@include desktop {
  .navswiper {
    div {
      gap: 10px;
      justify-content: center;
      transform: none !important;
      @media (min-width: 1439px) {
        gap: 24px;
      }
    }
  }
}

.versions:after {
  content: "";
  background: url("/images/Versions/Background.webp") no-repeat center center;
  position: absolute;
  bottom: 39%;
  width: 100%;
  height: 50%;
  background-size: cover;

  @include desktop {
    height: 100%;
    bottom: 0;
  }
}
