@import "@styles/theme", "@styles/mixins";

.container {
  position: relative;
  background: linear-gradient(to right, $primary, $terciary);
  @include desktop {
    background: $background-primary;
  }
  .sectionName {
    padding-top: toRem(28);
    text-align: center;
    padding-bottom: toRem(32);
    font-size: clamp(#{toRem(28)}, 2.6vw, #{toRem(32)});
    font-weight: $font-weight-regular;
    text-transform: uppercase;
    margin: 0 auto;
    width: 80vw;
    color: $text-primary;
    z-index: 9;
    position: relative;
    span {
      font-weight: $font-weight-bold;
    }
    @include desktop {
      padding-top: toRem(48);
      padding-bottom: toRem(48);
    }
  }
  .white {
    background: linear-gradient(to right, $primary, $terciary);
    width: 100%;
    position: absolute;
    top: 0;
    height: 21em;;

    @media (min-width: 1335px) {
      height: 22.5em;
    }
    @media (min-width: 1775px) {
      height: 25em;
    }
  }
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: toRem(30) !important;
  border-top: 1px solid #ffffff1a;
  @include desktop {
    margin-top: toRem(0) !important;
    padding-top: toRem(0) !important;
    padding-bottom: toRem(65) !important;
  }
  border-top: 3px solid;
  border-image: linear-gradient(to right, $primary, $terciary) 1;
}
