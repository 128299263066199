@import "@styles/theme", "@styles/mixins";

.container {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  h1 {
    display: none;
  }

  .slides {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .content {
      width: 100%;
      height: 100%;
      position: absolute;
      margin: 0 auto;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      flex-direction: column;



      .descriptionContent {
        width: 25%;
        top: 22%;
        left: 12%;
        position: relative;




        p {
          margin-top: 15px;
          line-height: 24px;
          max-width: 100%;
          font-size: clamp(1.12rem, 1.01vw, 1.375rem) !important;
          color: #fff;

          span {
            color: $primary;
            font-weight: 800;
          }
        }
      }

      .first_descriptionContent {
        width: 100%;
        top: 88px;
        position: absolute;
        margin: auto;
        height: calc(90% - 88px);

        svg {
          margin: auto;
          width: 100%;
        }



      }
    }
  }
}

.bottomRightSvg {
  position: absolute;
  bottom: 21%;
  right: 11%;
}


.suaVida {
  position: absolute;
  width: 19%;
  height: 100%;
  top: 25%;
  left: 11%;

  @media (min-width: 1400.1px) {
    top: unset;
    bottom: 50%;
    left: 9%;
    width: 18%;

  }

  max-height: clamp(20%, 5vw, 25%);

  .svgContainer {
    width: 104px;
    height: 134px;
    margin-left: 95px;
  }
}

.argoLogo {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: clamp(3%, 4%, 5%);
}

.car {
  position: absolute;

  width: 60vw;
  height: auto;
  aspect-ratio: 1.78 !important;
  right: -8%;
  bottom: 5%;

  @media (min-width: 1400.1px) {
    max-width: 1120px;
    bottom: 12%;
  }
}