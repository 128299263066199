@import "@styles/theme", "@styles/mixins";

.container {
  position: relative;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h2 {
    color: $default-white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-weight: $font-weight-extrablack;
    font-size: 3rem;

    em {
      background: linear-gradient(
        0deg,
        rgba(255, 27, 47, 1) 0%,
        rgba(253, 170, 43, 1) 80%
      );
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      padding-left: 1rem;
      font-weight: $font-weight-extrablack;
      font-style: normal;
    }
  }

  .ctas {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1.5rem;
    padding-top: 1.5rem;

    .cta {
      width: 358px !important;
      div {
        height: 48px;
      }
      p {
        font-size: 0.875rem;
        font-weight: $font-weight-bold;
        font-style: italic;
      }
    }
  }
}
