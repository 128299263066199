@import '@styles/theme', '@styles/mixins';

.title {
  width: 100%;
   
    padding-top: toRem(28);
    text-align: center;
    font-size: clamp(#{toRem(28)}, 2.6vw, #{toRem(32)});
    font-weight: $font-weight-regular;
    text-transform: uppercase;
    // margin: 0 auto;
    width: 100%;
    color: $text-primary;
    span {
      font-weight: $font-weight-bold;
    }
    @include desktop {
      padding-top: 0;
      display: inline-block;
    }
}