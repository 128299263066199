@import "@styles/theme", "@styles/mixins", "@styles/animations";

.slideContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @include desktop {
    flex-direction: row-reverse;
    padding: 0 30px;
    align-items: flex-end;
  }
}
.featContainer {
  max-width: 500px;
}
.nameContainer {
  margin-bottom: 20px;
  padding-left: 15px;
  .firstName {
    font-size: clamp(1.75rem, 10vw, 5rem);
    text-transform: uppercase;
    color: $text-primary;
    font-weight: $font-weight-extrablack;
  }

  .secondName {
    font-size: clamp(1.4rem, 2.6vw, 2rem);
    text-transform: uppercase;
    color: $text-primary;
  }
}

.listThumb {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;

  .item {
    flex: 1;
    width: 108px;
  }

  .thumb {
    background-color: gray;
    min-height: 80px;
    width: 100%;
  }

  .description {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: $primary;
    padding: 5px;
    height: 48px;
    p {
      font-weight: $font-weight-bold;
      font-size: clamp(12px, 12px, 1rem);
    }
  }
}

.buttonGroup {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 30px;

  @include desktop{

    width: clamp(200px, 100%, 200px);
  }

  & > a {
    cursor: pointer;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    width: auto;
    background-color: $input-box-quaternary;
    color: $primary;
    text-decoration: none;

    span {
      font-size: clamp(16px, 100%, 16px);
      color: $primary;
      font-weight: $font-weight-semibold;
    }

    &:hover{
      background-color: $primary;

      span{
        color: $text-primary;
      }

      svg{
        path{
          fill: $text-primary
        }
      }
  }}
}

.productImageContainer {
  position: relative;
  top: 50%;
  width: 100%;
  aspect-ratio: 16/9;


  svg{
    position: absolute;
    z-index: -1;
    height: 95%;
    right: -15%;
    width: 100%;

  }

  @include desktop {
    flex: 0.5 1 50%;
    max-width: 800px;
  }
}

