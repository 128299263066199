@import "@styles/theme", "@styles/mixins";

.container {
  padding-top: 0 !important;
}

.pageHeading {
  position: absolute;
  width: 100%;
  z-index: -1;
}

.firstSlide {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  border: 1px solid green;

  img {
    width: calc(100% - 110px);
    max-width: 333px;
    margin: calc(50px + 10%) auto auto 60px;

    @include sm {
      margin: calc(20px + 10%) auto 0 15%;
    }
  }
}

.slides {
  display: flex;
  width: 100%;
  height: 50%;
  flex-direction: column;

  h3 {
    display: block !important;
    font-weight: 400;
    text-shadow: 2px 2px #{$text-secondary};

    strong {
      display: inline !important;
      font-weight: 800;
    }
  }

  .content {
    justify-content: flex-start;
    align-items: flex-start;
    margin: calc(8.5% + 50px) 0 0 10%;
    width: max-content;
    height: fit-content;
  }

  .descriptionContent {
    margin-top: #{toRem(24)};
    font-size: 18px;
    max-width: calc(100vw - 80px);
  }

  .slidesContainer {
    width: 100%;
    height: 100%;

    .topBox {
      height: 40%;
      display: flex;
      justify-content: center;
      align-items: center;

      .wrapLogo {
        img {
          width: 100%;
        }
      }
    }

    .bottomBox {
      height: 78%;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      padding: 25% 5% 0;

      h2 {
        font-size: 2.5rem;
        text-transform: uppercase;
        color: $secondary;
        font-weight: 800;
        position: relative;
      }

      p {
        padding-top: 24px;
        font-weight: 400;
        font-size: 1rem;
        font-family: "Futura";
        color: #fff;
        padding-right: 32px;
      z-index: 999;

        span {
          color: $secondary;
          font-weight: 800;

        }
      }
    }
  }
}

.kvtext {
  top: 150px;
  margin: auto;
  width: 100%;
  position: absolute;
}

.newFiatContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;


}