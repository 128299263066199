@import "@styles/theme", "@styles/mixins", "@styles/animations";

.container {
  background-color: $background-primary;
  min-height: $min-mobile-height;
  padding: 0 !important;
  padding-top: calc(#{$header-size}) !important;

  .white {
    background: linear-gradient(to right, $primary, $terciary);
    width: 100%;
    height: 12%;
    position: absolute;
    bottom: -40px;
  }

  .sectionName {
    width: 100%;
    padding-top: toRem(28);
    text-align: center;
    padding-bottom: toRem(32);
    font-size: clamp(#{toRem(28)}, 2.6vw, #{toRem(32)});
    font-weight: $font-weight-regular;
    text-transform: uppercase;
    display: inline-block;
    width: 100%;
    color: $text-primary;
    span {
      display: inline;
      font-weight: $font-weight-bold;
    }
    @include desktop {
      padding-top: 0;
      padding-bottom: toRem(48);
      display: inline-block;
    }
  }

  @include desktop {
    min-height: 700px;
    padding-top: toRem(75) !important;
  }
  border-bottom: 1px solid;
  border-image: linear-gradient(to right, $primary, $terciary) 1 !important;
}
.expandedGallery {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $background-dark;
  z-index: 1001;
  @include desktop {
    background-color: rgba($background-dark, 0.9);
  }
  .closeButton {
    position: absolute;
    right: 0;
    top: 0;
    padding: 1rem;
    z-index: 100;
    color: $text-primary;
  }
  .controls {
    margin: 26px 0 0 !important;
    padding: 0 #{$section-horizontal-padding} !important;
    max-width: 100vw;
  }
  .swiperMobile {
    padding: 0 !important;
  }
}

.controlsDesktop {
  button {
    position: absolute;
    z-index: 1003;
    &.arrowRight {
      right: 30px;
      top: calc(50% - 18px);
    }
    &.arrowLeft {
      left: 30px;
      top: calc(50% - 18px);
    }
  }
}

.expandIcon {
  position: absolute;
  z-index: 2;
  margin: 1rem;
  right: 0;
  top: 0;
}

.videoHolder {
  position: absolute;
  left: 0;
  top: 0;
  width: 100% !important;
  height: 100% !important;
}

.controls {
  position: relative;
  margin-top: toRem(26);
  margin-bottom: toRem(36);
  z-index: 3;
  width: 100%;
  display: flex;
  justify-content: space-between;
  .prevArrow,
  .nextArrow {
    background-color: transparent;
  }
  .bullet {
    width: unset !important;
    height: 28px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &.active {
      @include bulletActive($input-box-primary);
    }
    @include desktop {
      &:hover {
        @include bulletActive($terciary);
      }
    }
    span {
      transition: all $transition-time-quick $transition-easing;
      display: block;
      background-color: $input-box-secondary;
      width: 8px;
      height: 8px;
    }
  }
}

.mainImage {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  height: 60vw;
  max-height: 400px;
  position: relative;
  @include desktop {
    max-height: auto;
    max-width: unset;
  }
  div,
  img,
  picture {
    position: absolute;
    left: 0;
    top: 0;
    width: 100% !important;
    height: 100% !important;
    @include desktop {
      height: unset !important;
    }
  }
}
.galleryContainer {
  margin-top: toRem(26);
}
.aligner {
  @include desktop {
    padding: 0 $section-horizontal-padding-desktop;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .thumbsDesktop {
    flex-grow: 1;
    width: 51%;
    min-width: 300px;
    max-height: 600px;
    position: relative;
    height: fit-content;
    display: flex;
    @include desktop {
      max-height: unset;
    }
    .leftSide,
    .rightSide {
      display: flex;
      flex-direction: column;
      width: 50%;
    }
    button {
      transition: opacity $transition-time-quick $transition-easing;
      &.active {
        opacity: 1;
      }
      img {
        width: 97%;
        max-width: unset !important;
        object-fit: cover;
        @media (min-width: 1440px){
          width: 96%;
          height: auto;
          aspect-ratio: 197/128;
        }
      }
    }
  }
}
.swiperMobile {
  padding: 0.5rem 0 0;

  .swiperSlide {
    width: 130px !important;
    overflow: visible !important;
  }

}

.thumbs {
  overflow: hidden;
  box-sizing: border-box;
  button {
    img {
      max-width: 130px !important;
      margin-bottom: toRem(5);
      object-fit: cover;
      aspect-ratio: 4/3;
      @include desktop {
        aspect-ratio: 16/9;
      }
      &:active {
        border: 3px solid $primary;
      }
    }
  }
}

.thumbActive {
  border: 4px solid $primary;
}

.expandedSlide {
  height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
  @include desktop {
    height: 100vh;
    padding: calc(#{$section-horizontal-padding-desktop} / 2) #{$section-horizontal-padding-desktop};
    img {
      max-height: calc(100vh - #{$section-horizontal-padding-desktop} / 2);
    }
  }
}
