@import "@styles/theme", "@styles/mixins", "@styles/animations";

.versionsContainer {
  position: relative;
  background-color: $background-primary;
  .versionsSkewnContainer {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    display: flex;
  }
}

.versionsTitle {
  font-size: clamp(#{toRem(28)}, 2.6vw, #{toRem(32)});
  color: $text-primary;
  padding: 0 20px;
  padding-top: 30px;
  

  text-transform: uppercase;
  font-weight: $font-weight-light;
  text-align: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 5px;

  span {
    font-weight: $font-weight-bold;
  }
}

.swiperWrapper {
  display: block;
  padding-top: toRem(0);
  width: 100%;
}

.container {
  min-height: $min-mobile-height;
  padding-top: calc(#{$header-size} + 14px) !important;
  @include desktop {
    padding-top: 14px !important;
    outline: 1px solid #{$background-secondary};
    min-height: 100vh;
    padding-bottom: toRem(68);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}

.slidePadding {
  padding: 0 #{$section-horizontal-padding};

  @include desktop {
    padding: 0 #{$section-horizontal-padding-desktop};
  }
}

.bg {
  width: 100% !important;
  height: 20%;
  min-height: 200px;
  top: 30%;
  left: 0;
  position: absolute;
  transition: background-color $transition-time-quick $transition-easing;
}

.bgMobile {
  width: calc(100% + 180px) !important;
  height: 20%;
  margin-left: calc(#{$section-horizontal-padding} * -1);
  min-height: 140px;
  top: calc(25% + 3vw);
  left: 0;
  position: absolute;
  transition: background-color $transition-time-quick $transition-easing;
}

.trapezoid {
  margin-top: toRem(32);
  height: calc(100% - 60px);
  width: auto;
}

.previous {
  position: absolute !important;
  left: 30px;
  top: 63%;
  transform: translateY(-50%);
  z-index: 4;
}

.next {
  position: absolute !important;
  right: 30px;
  top: 63%;
  transform: translateY(-50%);
  z-index: 4;
}

.cta {
  margin-top: toRem(30);
  margin-bottom: toRem(56);

  p:last-child {
    border-color: $text-secondary;
  }

  > div {
    padding: toRem(10) toRem(28);
    background-color: $text-secondary;
  }

  p {
    font-size: toRem(18) !important;
    margin-right: clamp(70px, 8vw, 100px);
  }

  &:hover {
    p {
      color: $text-secondary !important;
    }

    border-color: $text-secondary;

    svg {
      color: $text-secondary;
    }
  }
}

.swiperSlideMobile {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: visible;
  height: 200px;
  @include desktop {
  }
  button {
    padding-bottom: 6px;
  }

  &:last-of-type {
    margin-right: 20vw;
  }
}

.thumbs {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: toRem(26);
  padding: 0 $section-horizontal-padding;
  position: relative;
  z-index: 1;

  @include desktop {
    display: flex;
    padding: 0 clamp(20px, 2vw, 90px);
    margin-bottom: toRem(56);
  }

  @include xl {
    padding: 0 $section-horizontal-padding-desktop;
  }

  &.mobile {
    padding: 0 0 4px;
    max-height: 160px;
  }

  button {
    &.active .name {
      color: $text-primary;
    }
  }
}

.thumbContainer {
  min-height: 96px;
  position: relative !important;
  overflow: visible;
  display: flex;
  justify-content: flex-end;
  width: 100%;

  aspect-ratio: 4/2;

  .thumbImage {
    display: flex;
    justify-content: flex-end;
    width: 60%;
    position: relative;
    top: 60%;
    left: 0px;
    min-height: 100px;
  }

  p {
    color: $text-primary;
    margin-top: toRem(8);
    line-height: toRem(38);
    position: absolute;
    font-weight: $font-weight-semibold;
    top: 0px;
    left: 3rem;
    width: 100%;
    line-height: 20px;
    text-align: start;
  }

  .thumbBackground {
    width: 70%;
    height: 100%;
    top: 0;
    left: 15%;
    position: absolute;
    background-color: $input-box-primary;
    z-index: -1;
    border: 1px solid $input-box-quaternary;
    transform: skewX(-20deg);
  }

  &.active,
  &:hover {
    .thumbBackground {
      background-color: #fb1533;
      border: none;
    }
    p {
      color: $text-secondary;
    }
  }
}

.scrollCta {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  font-size: 1rem;
  color: $text-secondary;
  padding-top: 14px;
  padding-left: 10px;

  svg {
    margin-top: 9px;
    @include animationMargin;
  }
}