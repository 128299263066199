@import "@styles/theme", "@styles/mixins";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: $background-primary;
  @include desktop {
    padding-top: toRem(48);
  }
}

.footerIbamaMobile {
  margin-bottom: toRem(90);
  text-align: center;
}

.conditional,
.buttonsHolder {
  background: linear-gradient(to right, #fdb72b, #ff1430);
  width: 100%;
  padding-top: 2rem;
}

.conditional {
  padding-bottom: 20px;
  h2 {
    color: $default-white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: $font-weight-extrablack;
    font-size: 2em;
    padding: 23px 0;
    em {
      background: linear-gradient(
        0deg,
        rgba(255, 27, 47, 1) 0%,
        rgba(253, 170, 43, 1) 80%
      );
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      padding-left: 1rem;
      font-weight: $font-weight-extrablack;
      font-style: normal;
    }
  }
}

.buttonsHolder {
  display: flex;
  flex-direction: column;
  padding: 0 toRem(26);
  .menuButton {
    margin-bottom: toRem(8);
    width: 100%;
    line-height: toRem(34);
    svg {
      color: $text-primary;
    }
    &:after {
      z-index: 0 !important;
    }
    p {
      font-size: 0.875rem;
      font-style: italic;
    }
  }
}
.brand {
  width: 36px;
  height: auto;
}
.social {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-top: toRem(45);
  width: 100%;
  padding-bottom: toRem(40);
  div {
    display: flex;
    flex-direction: row;
  }
  .description {
    text-align: center;
    padding: 0 40px;
    padding-top: 30px;
    width: 80%;
    font-family: $font;
    font-weight: $font-weight-regular;
  }
  @include desktop {
    padding-top: toRem(45);
  }
  a {
    position: relative;
    display: block;
    overflow: hidden;
    text-indent: -2000px;
    width: toRem(36);
    &:not(:last-child) {
      margin-right: toRem(36);
    }
    color: $text-primary;
    text-align: center;
    &:hover,
    &:active {
      color: $primary;
    }
    &:before {
      font-family: $fontawesome-brands;
      font-size: toRem(18);
      content: attr(data-before);
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: toRem(-9);
      margin-top: toRem(-12);
      display: block;
      text-indent: 0;
    }
  }
}
.footerIbamaDesktop {
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    margin-top: toRem(9);
  }
}