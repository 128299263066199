@import "@styles/theme", "@styles/mixins";

.container {
  height: auto;
  min-height: 640px;

  @include desktop {
    min-height: inherit;
  }
}

.swiperSlide {
  padding: 0 #{$section-horizontal-padding};
  @include desktop {
    padding: 0 #{$section-horizontal-padding-desktop};
  }
}
