@import "@styles/theme", "@styles/mixins", "@styles/animations";

.primary {
  background-color: red;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 16px;
  position: relative;
  cursor: pointer;

  svg {
    position: absolute;
    right: 16px;
    margin: auto;
  }

  transition: 0.2s ease-in-out;

  &:hover {
    background-color: $secondary;
  }
  strong {
  color: #fff !important;
  }

}

.secundary {
  background-color: $secondary;


  span {
    border: none !important;
    text-decoration: none !important;
    outline: none;
  }
  strong {
    color: $text-menu-link !important;
  }

  

  &:hover {
    background-color: $primary;
  }
  

}

.outline {
  background-color: transparent;
  border: 1px solid $primary;
  color: $primary;
  & svg path {
    stroke: $primary;
  }

  &:hover {
    color: $text-secondary;
    opacity: 1;
    background-color: $primary;
    & svg path {
      stroke: $text-secondary;
    }
  }
}

.disable {
  opacity: 0.3;
  cursor: auto;
  &:hover {
    opacity: 0.3;
  }
}
