@import '@styles/theme', '@styles/mixins', '@styles/animations';

.sectionContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  // height: 420px;

  aspect-ratio: 3.48;
  background-image: url('/images/banner-125/background/fiat-125-anos@3x.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 2px;

  @include mobile {
    padding-right: 35px;
    padding-left: 25px;

    background-position: top;
    background-size: contain;
    background-repeat: repeat;
    justify-content: flex-end;

    height: 732px;
    width: 100%;

    // aspect-ratio: 0.65;
    background-image: url('/images/banner-125/background/fiat-125-anos-mobile.webp');
  }

  .content {
    padding-top: 53px;
    padding-bottom: 42px;
    max-width: 1336px;
    display: flex;
    flex-direction: row-reverse;
    align-items: top;
    justify-content: space-between;
    width: 100%;
    color: #393939;

    &.rowInverse {
      @include desktop {
        flex-direction: row-reverse;
      }
    }

    @include mobile {
      height: 732px;
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  .column {
    display: flex;
    flex-direction: column;
    position: relative;
    @include mobile {
    }
  }

  .row {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    @include mobile {
    }
  }

  .contentText {
    width: 533px;
    padding-right: 88.4px;
    padding-top: calc(132px - 53px);

    h2 {
      font-weight: 400;
      position: relative;
      text-transform: uppercase;
      font-size: 2rem;
      &::after {
        content: '';
        position: absolute;
        width: 120px;
        background-color: #393939;
        height: 4px;
        bottom: -13px;
      }
    }

    p {
      padding-top: 25px;
      strong {
        text-transform: uppercase;
        font-weight: bold;
      }
    }

    @media (max-width: 1300px) {
      h2 {
        font-size: 1.3rem;
      }

      P {
        font-size: 0.75rem;
      }
    }

    @include mobile {
      padding: 0px;
      width: 100%;
      color: #f7e8e0;
      position: relative;
      top: -50px;

      h2 {
        font-size: 1.375rem;
        &::after {
          content: '';
          position: absolute;
          width: 120px;
          background-color: #f7e8e0;
          height: 4px;
          bottom: -13px;
        }
      }

      p {
        font-weight: 100;
      }
    }
  }

  .model {
    position: absolute;
    top: 40px;
    right: -20px;
    img {
      width: 600px;
    }

    @media (max-width: 1444px) {
      right: 20px;
      img {
        width: 525px;
      }
    }

    @media (max-width: 1300px) {
      right: 80px;
      top: 50px;
      img {
        width: 420px;
      }
    }

    @include mobile {
      position: relative;
      top: -30px;
      left: -50px;
      width: 100%;
      margin: 0 auto;
      right: inherit;

      @media (max-width: 400px) {
        left: -80px;
      }

      img {
        overflow: hidden;
        object-fit: cover;
        width: auto;
        height: 271px;
      }
    }
  }

  .couple {
    padding-right: 56px;
    padding-left: 86px;
    img {
      height: 169px;
    }
    @include mobile {
      padding: 0;
      padding-top: calc(109px - 53px);
      width: 100%;
      display: flex;

      justify-content: flex-end;
      padding-right: 10px;
      height: fit-content;
      img {
        height: auto;
        width: 233px;
        object-position: right center;
      }
    }
  }

  .logo {
    position: absolute;
    top: -40px;
    left: -35px;
    img {
      height: 120px;
    }

    @media (max-width: 1300px) {
      img {
        height: 100px;
      }
    }

    @include mobile {
      top: 0;
      left: 0;
      width: 100%;
      position: absolute;

      img {
        width: 100%;
        object-fit: contain;
        overflow: hidden;
      }
    }
  }

  .lettering {
    position: relative;
    top: 30px;
    img {
      height: 48px;
    }
    @include mobile {
      display: flex;
      position: inherit;
      justify-content: flex-end;
      padding-top: 11px;
    }
  }

  .cta {
    position: absolute;
    bottom: -45px;
    width: 387px;
    height: fit-content;

    a {
      &:hover {
        border: none;
      }
    }

    div {
      height: 46px !important;
      p {
        padding: 0;
        font-weight: 500;
      }
    }

    @include mobile {
      width: 100%;
      padding-top: 10px;
      bottom: -85px;
    }
  }
}
