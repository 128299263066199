@import "@styles/theme", "@styles/mixins";
.slideContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;

  flex-direction: column;
  @include desktop {
    flex-direction: row;
  }
}

.slideContainerMobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  min-height: 350px;
}

.slideDetails {
  margin-top: clamp(100px, 80vw, 370px);
  margin-left: auto;
  margin-right: auto;
  flex-shrink: 0;
  line-height: 1.4;
  max-width: 400px;

  h3 {
    color: $text-primary;
    font-weight: 500;
    font-size: toRem(18);
  }

  h2 {
    text-align: left !important;
    width: 100%;
    color: $text-primary;
    text-transform: uppercase;
    font-size: toRem(20);
    font-weight: bold !important;
    margin: 0 0 toRem(28);
    padding: 0 !important;
  }

  .slideDescription {
    font-size: toRem(16);
  }
}
