@import "@styles/theme", "@styles/mixins";

.nextTab {
  margin-left: 16px;
}

.controls {
  position: absolute;
  bottom: 0;
  left: #{$section-horizontal-padding-desktop};
  min-width: 350px;
  z-index: 4;

  .arrows {
    padding-bottom: 4px;
    display: flex;

    button:first-of-type {
      margin-right: 8px;
    }
  }

  .bullets {
    margin-left: -10px;
    margin-bottom: 28px;
  }

  .bullet {
    width: 30px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &.active {
      @include bulletActive($primary);
    }

    @include desktop {
      &:hover {
        @include bulletActive($secondary);
      }
    }

    span {
      transition: all $transition-time-quick $transition-easing;
      display: block;
      background-color: $bullet-color;
      border-radius: 50%;
      width: 8px;
      height: 8px;
    }
  }
}
