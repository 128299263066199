@import "@styles/theme", "@styles/mixins";

.container {
  position: relative;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.bannerContainer {
  width: 100%;
  height: 100%;
  min-height: 600px;
  background: rgb(253, 183, 43);
  background: linear-gradient(
    90deg,
    rgba(253, 183, 43, 1) 0%,
    rgba(252, 20, 48, 1) 100%
  );
  display: flex;
  gap: 50px;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  @include desktop {
    padding: clamp(90px, 10em, 120px);
    flex-direction: row;
  }
  .bannerDescription {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 20px;
    flex: 1;
    padding-top: 0px;
    h2 {
      color: $text-primary;
      font-weight: $font-weight-extrablack;
      margin-bottom: 25px;
      font-size: clamp(#{toRem(40)}, 2.6vw, #{toRem(64)});
      span {
        font-size: clamp(#{toRem(64)}, 2.6vw, #{toRem(130)});
        font-weight: $font-weight-extrablack;
        line-height: 70%;
      }
      @include desktop {
        font-size: clamp(#{toRem(32)}, 32px, #{toRem(64)});
        span{
          font-size: clamp(#{toRem(64)}, 64px, #{toRem(130)});
        }
      }
    }
    h3 {
      font-size: clamp(#{toRem(20)}, 2.6vw, #{toRem(28)});
      font-weight: $font-weight-semibold;
      @include desktop {
        line-height: 90%;
        font-size: claamp(16px, 20px, 3em);
      }
    }
    p {
      font-size: clamp(#{toRem(16)}, 16px, #{toRem(20)});
      font-weight: $font-weight-regular;
      padding-bottom: 20px;
    }
  }

  .bannerImage {
    flex: 1.5;
    position: relative;
    z-index: 1;
    aspect-ratio: 16 /9;
    margin-top: 25px;
    margin-bottom: 40px;
    width: 343px;
    @include desktop {
      width: 100%;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

.cta {
  width: 248px;
  position: absolute;
  bottom: clamp(20px, 2vw, 95px);
  right: clamp(65px, 2vw, 95px);
}

.ctaMobile {
  width: 100%;
  position: absolute;
  bottom: clamp(35px, 2vw, 55px);

  display: flex;
  justify-content: center;
  align-items: center;
}
