@import '@styles/theme', '@styles/mixins';

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 #{$section-horizontal-padding} #{toRem(52)};

  @include desktop {
    padding: 0 #{$section-horizontal-padding-desktop} #{toRem(52)};
  }

  .tab {
    text-decoration: none;
    position: relative;
    text-align: center;
    flex-grow: 1;
    margin: 0;
    border: none;
    .content {
      position: relative;
      z-index: 2;
      background-color: $background-secondary;
      line-height: toRem(32);
      color: $text-secondary;
      padding: toRem(5) toRem(10);
      transition: all $transition-time-quick $transition-easing;
      p {
        text-transform: capitalize;
      }
    }
.active{
  background-color: $secondary;
  color: $background-dark;
}
    &:hover,
    &.active {
      .content {
        background-color: $secondary;
        color: $background-dark;
      }
    }
  }
}
